import React from "react";
import { secondsToMMSS } from "../../utils/secondsToMMSS";

export function RoundDetails({ timer, currentActivity, nextActivity, nextNextActivity }) {
	return (
		<div className="dash-round-details live-row col"> 
			<h2>Current</h2>
			<div className="current-activity">
				{currentActivity && currentActivity.name}
			</div>
			<div className="activity-timer-wrapper">
				{currentActivity && <div className="activity-time">{secondsToMMSS(currentActivity.endTime - timer)}</div>}
				{currentActivity &&
					<div className="activity-timer">
						<div className="progress-indicator-outer">
							<div className="progress-indicator-inner" style={{ width: ((timer - (currentActivity.endTime - currentActivity.duration)) / currentActivity.duration) * 100 + '%' }}><div className="progress-indicator-mark"></div></div>
						</div>
					</div>}
			</div>
			<h3>Coming Next</h3>
			<div className="up-next-wrapper">
				{nextActivity &&
					<div className="next-activity">
						{nextActivity.name}
					</div>
				}
				{nextNextActivity &&
					<div className="next-activity">
						{nextNextActivity.name}
					</div>
				}
			</div>
		</div>
	);
}