import React,{useState} from 'react';
import "./workoutComponent.css"
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Icon } from '@iconify/react';




function EditPoster(props) {

  const [image, setImage] = useState(props.defaultPoster);
  const [cropData, setCropData] = useState("#");//will be # if yo do not move cropper window
  const [cropper, setCropper] = useState();

  // console.log(`IMAGE in state: ${image}`);
  //updload new file to canvas
  const onChange = (e) => {

    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const rotate =(isRight)=>{
    let direction = isRight ? 90 :-90;
    cropper.rotate(direction)
  }

  function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

//Confirm
const getCropData = () => {
    if (typeof cropper !== "undefined") {
      
      setCropData(cropper.getCroppedCanvas().toDataURL());
      props.getCroppedData(cropper.getCroppedCanvas().toDataURL())


      const imageUpload = dataURItoBlob(cropper.getCroppedCanvas().toDataURL())
      props.fileChangedHandler(imageUpload);

      props.changeState()
    }
  };

    return (props.trigger)?(
        <div className = "popup">
            <div className = "popup-inner">
                <div className = "img-container-interactive">
                <Cropper
                  style={{ height: "100%", width: "100%" }}
                  zoomTo={0.5}
                  aspectRatio={3/4}
                  preview=".img-container-preview"
                  src={image}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                  setCropper(instance);
                  }}
                  guides={true}
                />
                </div>



              {/* <div className = "img-container-preview"/> this is the image preview. in the style guide there was no preview so i took it out*/}
              <div className = "photo-tool-bar">
                <label className = "photo-tool-icons" id="blue-icons" htmlFor="file-upload">{<Icon icon="bx:image-add" width="24"  />}</label>
                <input type="file"  onChange={onChange} id="file-upload"/>
                <label className = "photo-tool-icons" id="blue-icons" onClick= {()=>rotate(true)}>{<Icon icon="bx:rotate-right" width="24"/>}</label>
                <label className = "photo-tool-icons" id="blue-icons" onClick ={() => rotate(false)}>{<Icon icon="bx:rotate-left" width="24"/>}</label>
                <label className = "photo-tool-icons" id="red-icons" onClick={props.changeState}>{<Icon icon="bi:x-circle-fill" width="24"/>}</label>
                <label className = "photo-tool-icons" id="green-icons" onClick={getCropData}>{<Icon icon="bi:check-circle-fill"width="24" />}</label>
              </div>
            </div>
        </div>
    ):"";
}

export default EditPoster;