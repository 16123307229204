import React, { useState} from 'react';
import EditPoster from "./editPoster"
import WorkoutPoster from "./workoutPoster"


const PosterManager = ({getCroppedData,defaultPoster,posterUrl,fileChangedHandler,fileinputGroupSuccess,posterError,croppedData}) => {
    const [isEditing, setIsEditing] = useState(false);

    const editImageTrigger = () =>
    {
        
        setIsEditing(!isEditing)
        console.log("Editing "+ isEditing)
    }

    return (
        <div>
            <WorkoutPoster
                posterUrl={posterUrl}
                fileChangedHandler={fileChangedHandler}
                fileinputGroupSuccess={fileinputGroupSuccess}
                posterError={posterError}
                croppedData ={croppedData}
                editImageTrigger ={editImageTrigger}
            />
            <EditPoster trigger ={isEditing} 
            changeState={editImageTrigger} 
            getCroppedData = {getCroppedData} 
            defaultPoster = {defaultPoster} 
            fileChangedHandler={fileChangedHandler}
            croppedData ={croppedData}

 />
        </div>
    );
};

export default PosterManager;