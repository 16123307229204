import config from "../config";
import {calculateDefaultReps} from '../utils/calculateDefaultReps';

export const updateDurationDetails = (value) => {
  let round = {};
  let difficultyKey = config.roundDifficulty.findIndex((x) => x.id === value);
  const configVal = config.roundDifficulty[difficultyKey];
  round["coolDownTime"] = configVal.cooldown;
  round["warmUpTime"] = configVal.warmup;
  round["duration"] = configVal.duration;
  return round;
};

export const updateStrikesCombosDetails = (value) => {
  let round = {};
  round["disabled"] = !value;
  round["combos"] = {};
  round["moves"] = {};
  return round;
};

export const handleMultidropdowmData = (id, strikesInfo, name) => {
  let round = {};
  let strikes = { ...strikesInfo[name] };
  if (strikes[id]) {
    delete strikes[id];
  } else {
    strikes[id] = id;
  }
  round[name] = strikes;
  return round;
};

export const addOrDeleteActivity = (
  rounds,
  name,
  roundId,
  obj = {},
  i,
  deleteActivity = false
) => {
  const roundsRes = [...rounds].map((data, index) => {
    if (index === roundId) {
      return {
        ...data,
        [name]: deleteActivity
          ? data[name]?.filter((_, k) => k !== i)
          : data[name]?.concat(obj),
      };
    }
    return data;
  });
  return roundsRes;
};

export const updateActivity = (activityList, rounds, i, index, roundName, updateValues) => {
  const res = [...rounds].map((data, key) => {
    if (key === i) {
      return {
        ...data,
        [roundName]: data[roundName]?.map((activity, j) => {
          if (index === j) {
            const initialMerge = {...activity, ...updateValues};
            const repsCalcMerge = {...initialMerge, ...(initialMerge.repsChecked && {reps: calculateDefaultReps(activityList, initialMerge.activityListId, initialMerge.durationSec)})}
            return repsCalcMerge;
          }
          return activity;
        }),
      };
    }
    return data;
  });
  return res;
};

export const clearErrors = (roundError) => {
  const res = [...roundError].map((data) => {
    return {
      ...data,
      activityError: [],
    };
  });
  return res;
};

export const generateRoundsList = (rounds) => {
  const roundList = rounds.map((item, index) => {
    return {
      isDefensive: item.isDefensive,
      workoutTypeId: item.workoutTypeId,
      coolDownTime: item.coolDownTime || 0,
      roundDifficulty: item.roundDifficulty,
      warmUpTime: item.warmUpTime || 0,
      stanceTypeId: item.stanceTypeId,
      duration: item.duration,
      sequenceNum: index + 1,
      strikes: Object.values(item.strikes),
      moves: Object.values(item.moves),
      combos: Object.values(item.combos),
      activities: item.activity_info,
    };
  });
  return roundList;
};
