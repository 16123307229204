import React, { useState, useEffect } from "react";
import config from "../../config";
import { getRoundStructure } from "../../utils/utils";
import { parseStringToObject } from "../../utils/parseStringToObject";
import { createWorkout, saveToDraft, workoutDetails, deleteWorkout } from "../../services/userService";
import useCallApi from "../../hooks/useCallApi";
import useUploadPoster from "../../hooks/useUploadPoster";
import useWorkoutForm from "../../hooks/useWorkoutForm";
import useModal from "../../hooks/useModal";
import "react-datepicker/dist/react-datepicker.css";
import "../../common/style.css";
import { getStartingDate } from "../../utils/getStartDate";
import { calculateDefaultReps } from "../../utils/calculateDefaultReps";
import ActivityComponent from "./activityComponent";
import WorkoutCreate from "./workoutCreate";
import Loader from "../../common/loader";
import { convertDateAddOffset } from "../../utils/convertTimeZone";
import { validationSchema, submitValidationSchema} from "../../utils/validationSchema";
import { updateDurationDetails, updateStrikesCombosDetails, generateRoundsList, updateActivity } from "../../utils/formHelpers"
import MMAInfo from "./MMAInfo";
import WorkoutDatePicker from "./workoutDatePicker";
import DurationComponent from "./durationComponent";
import DeleteWorkoutInfo from "./deleteWorkoutInfo";
import RoundDurationDetails from "./roundDurationDetails";
import SubmitForm from "./submitForm";
import AddActivity from "./addActivity";
import AddRounds from "./addRounds";
import WorkoutTypeDetails from "./workoutTypeDetails";
import Modal from "../../common/modal";
import ActivityErrorMessage from "./activityErrorMessage";
import WorkoutDescription from "./workoutDescription";
import PosterManager from "./posterManager";
import Add_Image from "../../images/Add_Image.png";


export default function WorkoutFormHook(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [mmaVal, setMmaVal] = useState(0);
  const [padVal, setPadVal] = useState(0);
  const [levelId, setLevelId] = useState(0);
  const [dateError, setDateError] = useState("");
  const [levelIdError, setLevelIdError] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [workoutDescription, setWorkoutDescription] = useState("");
  const [workoutTitle, setWorkoutTitle] = useState("");

  const [croppedData,getCroppedData] = useState(Add_Image);
//"https://cdn3.iconfinder.com/data/icons/eldorado-stroke-symbols/40/shape_square-512.png"
  const [defaultPoster,] = useState(
    "https://n3devstorage.blob.core.windows.net/workout-session/Posters/default_workout_poster.png"
  );

  const [
    rounds,
    roundsError,
    duration,
    setDuration,
    setWorkoutDetails,
    setWorkoutErrorDetails,
    handleAddRoundClick,
    handleDeleteRoundClick,
    updateRounds,
    updateRoundsError,
    clearDropDownData,
    handleActivityDuration,
    handleActivityReps,
    handleActivityRepsCheckbox,
    handleItemCommonSelected,
    handleAddActivity,
    handleRemove
  ] = useWorkoutForm();
  
  const [
    combos,
    activities,
    strikes,
    moves,
    pageErrors,
    isLoadingList,
    updateDropDownDetails,
    callWorkoutApis
  ] = useCallApi(levelId, padVal, props.history);

  const [
    posterError,
    fileinputGroupSuccess,
    fileChangedHandler,
    setPosterUrl,
    posterUrl,
    posterImageErrId,
  ] = useUploadPoster(false, null, null);

  const [modalDetails, handleModalOpen, handleModalClose] = useModal();

  useEffect(() => {
    const id = props.match.params.workoutId;
    if (id) {
      setTitle("Edit");
      setIsLoading(true);
      workoutDetails(id)
        .then((res) => {
          setIsLoading(false);
          if (res && res.data && res.data !== null) {
            const response = res.data;
            let rounds = [];
            let round = {};
            let roundsError = [];
            let combos = {};
            let strikes = {};
            let moves = {};
            response.rounds.map((item) => {
              combos = parseStringToObject(item.combos, "combos");
              strikes = parseStringToObject(item.strikes, "strikes");
              moves = parseStringToObject(item.moves, "moves", item.isDefensive);
              round = getRoundStructure(item, combos, strikes, moves);
              rounds.push(round);
              roundsError.push(config.roundError);
              return true;
            });
            setLevelId(response.levelId);
            setPadVal(response.padTypeId);
            setMmaVal(response.isMMA ? 1 : 0);
            setValuesForForm(response, rounds, roundsError)
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setPadVal(1)
      setTitle("Create");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update dropdown details
  useEffect(() => {
    if (padVal && levelId) {
      updateDropDownDetails();
    }
  }, [padVal, levelId, updateDropDownDetails]);

  const setValuesForForm = (response, rounds, roundsError) => {
    setPosterUrl(response.posterUrl)
    setDuration(response.duration);
    setStartDate(convertDateAddOffset(new Date(response.startsAt)));
    setWorkoutDetails(rounds);
    setWorkoutErrorDetails(roundsError);
  }

  const handleRadioPadsChange = (event) => {
    setPadVal(parseInt(event.target.value));
    clearDropDownData(rounds);
  };

  const handleRadioMmaChange = (event) => {
    setMmaVal(parseInt(event.target.value));
  };

  const handleItemSelected = (id) => {
    if (levelIdError) {
      setLevelIdError("");
    }
    setLevelId(id);
    clearDropDownData(rounds)
  };

  const handleDateTimeChange = (date) => {
    const error = validationSchema("date", date);
    setDateError(error);
    setStartDate(date);
  };

  const handleRadioButtonChange = (event, index) => {
    let disableTargetValue = parseInt(event.target.value) === 0 ? false : true;
    updateRounds(index, "isDefensive", disableTargetValue, updateStrikesCombosDetails);
  };

  const handleItemRoundSelected = (e, index, name) => {
    let workoutTypeIdErr = name === "workoutTypeId" ? "workoutTypeError" : "levelError";
    if (!levelId) {
      setLevelIdError("Please select difficulty to proceed");
      window.scrollTo(0, 0);
      return;
    }
    updateRoundsError(index, workoutTypeIdErr, "", true);
    const workoutValue = parseInt(e);
    updateRounds(index, name, workoutValue, null, true);
  };

  const handleItemRoundDifficulty = (e, index, name) => {
    updateRoundsError(index, "roundDifficultyError", "");
    updateRounds(index, name, parseInt(e), updateDurationDetails, false, true);
  };

  const handleRoundDetailsChange = (e, index) => {
    const { name, value } = e.target;
    const workoutValue = value && parseInt(value);
    updateRounds(index, name === "duration" ? name : `${name}Time`, workoutValue, null, false, true);
    const error = validationSchema(name, value);
    updateRoundsError(index, `${name}Error`, error);
  };

  const handleActivity = (e, i, index, name, activityList) => {
    const defaultDuration = activityList.find((data) => data.id === e).defaultDuration;
    
    const activityRes = updateActivity(activityList, rounds, i, index, "activity_info", {[name]: e, durationSec: defaultDuration, reps: calculateDefaultReps(activityList, e, defaultDuration), repsChecked: true});
    setWorkoutDetails(activityRes);
    if(roundsError[i]?.activityError[index]?.activityError){
      const activityErrRes = updateActivity(activityList, roundsError, i, index, "activityError", {"activityError": ""});
      setWorkoutErrorDetails(activityErrRes);
    }
  };
//here is where we validate the workouts data
  const handleButtonSubmit = (e, type) => {
    console.log("E:: "+e)
    console.log("Type:: "+type)
    console.log("Form Submit  title-> " + workoutTitle)
    console.log("Form Submit  description-> " + workoutDescription)
    if (!levelId) setLevelIdError("Please select difficulty to proceed");
    const error = validationSchema("date", startDate);
    setDateError(error);
    const [errorRes, hasError] = submitValidationSchema(rounds, roundsError, levelId, startDate);
    setWorkoutErrorDetails(errorRes);
    if(hasError){
      window.scrollTo(0, 0);
      return
    }
    const roundList = generateRoundsList(rounds)
      console.log("Form Submit  -> " + posterImageErrId)

    let requestBody = {
      posterUrl: posterImageErrId === 0 ? defaultPoster : posterUrl,
      startsAt: getStartingDate(startDate),
      duration: duration,
      levelId: levelId,
      padTypeId: padVal,
      isMMA: mmaVal === 0 ? false : true,
      rounds: roundList,
      workoutDescription: workoutDescription,
      workoutTitle: workoutTitle,

      //here is our request this shoudl be where we need to add desciption and titls
      // console.log("Form Submit  title-> " + workoutTitle)
      // console.log("Form Submit  description-> " + workoutDescription)
    };

    if (type === 1) {
      callWorkoutApis(saveToDraft, requestBody);
    } else {
      callWorkoutApis(createWorkout, requestBody);
    }
  };

  const handleAction = (confirmation) => {
    const requestBody = {
      id: props.match.params.workoutId,
    };
    handleModalClose();
    if (confirmation) {
      callWorkoutApis(deleteWorkout, requestBody);
    }
  };

  return (
    <>
      {(isLoading || isLoadingList) && <Loader />}
      <Modal
          title={modalDetails.title}
          msg={modalDetails.msg}
          handleAction={handleAction}
          handleModalClose={handleModalClose}
          show={modalDetails.show}
        />

      <div className="container-fluid workout">
        <WorkoutCreate>
          <DeleteWorkoutInfo title={title} handleModalOpen={handleModalOpen} />
        
          <div className="col-sm-12 page-errors">{pageErrors}</div>
          <div className="col-sm-4">
          <PosterManager 
          
          getCroppedData = {getCroppedData} 
          defaultPoster = {defaultPoster}
          posterUrl={posterUrl}
          fileChangedHandler={fileChangedHandler}
          fileinputGroupSuccess={fileinputGroupSuccess}
          posterError={posterError}
          croppedData ={croppedData}
          />
          </div>
          <div className="col-sm-8">
          <WorkoutDescription 
        setWorkoutDescription = {setWorkoutDescription} 
        setWorkoutTitle = {setWorkoutTitle} 
        workoutTitle = {workoutTitle}
        workoutDescription = {workoutDescription}/>
            <MMAInfo
              mmaVal={mmaVal}
              padVal={padVal}
              handleRadioPadsChange={handleRadioPadsChange}
              handleRadioMmaChange={handleRadioMmaChange}
            />
            <WorkoutDatePicker
              dateError={dateError}
              startDate={startDate}
              levelName={levelId}
              levelError={levelIdError}
              handleItemSelected={handleItemSelected}
              handleDateTimeChange={handleDateTimeChange}
            />
            <div className="round-details mt-50">
              <div className="col-sm-12 m-auto">
                <RoundDurationDetails
                  duration={duration}
                  roundsLength={rounds?.length || 0}
                />
                {rounds && rounds?.length > 0 && rounds.map((data, index) => (
                  <div key={data.id} className="round-box">
                    <span
                      className="delete-btn"
                      onClick={() => handleDeleteRoundClick(index)}
                    >
                      <i className="fa fa-times"></i>
                    </span>
                    <WorkoutTypeDetails 
                      workoutTypeId={data.workoutTypeId}
                      roundDifficulty={data.roundDifficulty}
                      index={index}
                      handleItemRoundSelected={handleItemRoundSelected}
                      handleItemRoundDifficulty={handleItemRoundDifficulty}
                      workoutTypeErr={roundsError[index]?.workoutTypeError}
                      difficultyErr={roundsError[index]?.roundDifficultyError}
                    />
                    <DurationComponent
                      roundsError={roundsError}
                      data={data}
                      handleRoundDetailsChange={handleRoundDetailsChange}
                      i={index}
                    />
                   <ActivityErrorMessage data={data} />
                    {data.workoutTypeId && data.workoutTypeId !== 6 ? (
                      <ActivityComponent
                        i={index}
                        data={data}
                        activityList={activities[config.workoutTypeIdObj[data.workoutTypeId]]}
                        errors={roundsError && roundsError[index]?.activityError}
                        combosError={roundsError && roundsError[index]?.combosError}
                        strikesError={roundsError && roundsError[index]?.strikesError}
                        movesError={roundsError && roundsError[index]?.movesError}
                        strikesList={strikes || []}
                        handleItemCommonSelected={handleItemCommonSelected}
                        combosList={data.isDefensive ? combos[2] : combos[1]}
                        moves_list={moves}
                        handleRadioButtonChange={handleRadioButtonChange}
                        handleRemove={handleRemove}
                        handleActivity={handleActivity}
                        handleActivityDuration={handleActivityDuration}
                        handleActivityReps={handleActivityReps}
                        handleActivityRepsCheckbox={handleActivityRepsCheckbox}
                      />
                    ) : (
                      ""
                    )}
                    <AddActivity workoutTypeId={data.workoutTypeId} index={index} handleAddActivity={handleAddActivity}  />
                  </div>
                ))}
                <AddRounds roundLength={rounds?.length} handleAddRoundClick={handleAddRoundClick}  />
                <SubmitForm
                  id={props.match.params.workoutId}
                  history={props.history}
                  handleButtonSubmit={handleButtonSubmit}
                  workoutTitle = {workoutTitle}
                  workoutDescription = {workoutDescription}

                />
              </div>
            </div>
          </div>
        </WorkoutCreate>
      </div>
    </>
  );
}
