import React from "react";
import LeaderBoardItem from "./leaderboardItem";

export function Leaderboard({ sortName, sortDirection, leaderBoard, trainerStrikes, handleSortPower }) {

	return (
		<div className="leaderboard-wrapper"> 
			<h2>Leaderboard</h2>
			<div className="leaderboard col">
				<div className="leaderboard-header">
					<div className="leaderboard-rank">Rank</div>
					<div className="leaderboard-name">User</div>
					<div
						className="pace-container leaderboard-power"
						onClick={() => handleSortPower("power", sortDirection === 'asc' ? 'dsc' : 'asc')}
					>
						Power
						{sortName === "power" && (
							<div className={sortDirection === 'asc' ? `sort-triangle-asc` : `sort-triangle-dsc`}></div>
						)}
					</div>
					<div
						className="pace-container leaderboard-pace"
						onClick={() => handleSortPower("pace", sortDirection === 'asc' ? 'dsc' : 'asc')}
					>
						Pace{sortName === "pace" && (
							<div className={sortDirection === 'asc' ? `sort-triangle-asc` : `sort-triangle-dsc`}></div>
						)}
					</div>
				</div>
				{/* local storage total strike divided by rolling power */}
				<div className="leaderboard-scroll">
					{leaderBoard
						?.filter(data => data.userId !== Number(localStorage.getItem("_user-id_")))
						.map((data, i) => (
						<div key={i} className="leaderboard-container">
							<LeaderBoardItem 
							i = {i} 
							data ={data} 
							trainerStrikes = {trainerStrikes}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}