import React from 'react';

export default function WorkoutDescription({setWorkoutDescription, setWorkoutTitle,workoutTitle,workoutDescription}){
    //consider removing the components states and just use passed props
    // const [titleName, setTitleName] = useState("")
    // const [description, setDescription] = useState("")
    const titleWordLimit = 25
    const descriptionWordLimit = 90


    const UpdateTitleField = title =>
    {
        // setTitleName(title.target.value)
        setWorkoutTitle(title.target.value);
    }

    const UpdateDescriptionField = desc =>
    {
        // setDescription(desc.target.value)
        setWorkoutDescription(desc.target.value)
    }
  


     return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="text-field-wrapper">
                            <label className ="workout-details-page">Workout Title</label>
                            <input 
                            type = "text"
                            maxLength={titleWordLimit}
                            value = {workoutTitle}
                            placeholder = "25 character limit"
                            onChange ={UpdateTitleField}
                            ></input>
                            <p className ="text-limit">{workoutTitle.length}/{titleWordLimit}</p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="text-field-wrapper">
                            <label className ="workout-details-page">Workout Description</label>
                            <textarea className ="medium-text-box"
                            type = "text"
                            maxLength={descriptionWordLimit}
                            value = {workoutDescription}
                            placeholder = "90 character limit"
                            onChange ={UpdateDescriptionField}
                            ></textarea>
                            <p className ="text-limit">{workoutDescription.length}/{descriptionWordLimit}</p>
                        </div>
                    </div>
               </div>
            </div>
        );
}

// export default workoutDescription

