import React from "react";

export default function WorkoutPoster({
  posterUrl,
  fileChangedHandler,
  fileinputGroupSuccess,
  posterError,
  croppedData,
  editImageTrigger
}) {
  return (
      <>
          <div className="poster-wrapper">
          <img
            src={posterUrl}
            className="edit-workout-image"
            alt="placeholder"
            onClick={editImageTrigger}
          />
        </div>
        <span
          className={`error-message fileinputGroup ${fileinputGroupSuccess}`}
        >
          <small>{posterError}</small>
        </span>
      </>
  );
}
