import { handleActivityDurationDifference } from "../utils/calculateDuartionDifference"
import { isEmpty } from "./isEmpty"

export const validationSchema = (name, value, isDefense) => {
  let errors = {};
  if (name === "levelId") {
    errors.levelError = value ? "" : "Required";
    return errors.levelError;
  }
  if (name === "typeId") {
    errors.workoutTypeError = value ? "" : "Required";
    return errors.workoutTypeError;
  }
  if (name === "date") {
    errors.date =
      new Date(value).getTime() < new Date().getTime() ? "Invalid start date" : "";
    return errors.date;
  }
  if (name === "difficulty") {
    errors.difficulty = value ? "" : "Required";
    return errors.difficulty;
  }
  if (name === "duration") {
    errors.durationError = handleDurationError(value);
    return errors.durationError;
  }
  if (name === "reps") {
    errors.repsError = handleRepsError(value);
    return errors.repsError;
  }
  if (name === "warmUp" || name === "coolDown") {
    errors[name] = handleWarmUpError(name, value);
    return errors[name];
  }
  if (name === "durationSec") {
    errors.durationSec = handleActivityDuration(value);
    return errors.durationSec;
  }
  if (name === "durationDiff") {
    errors.durationDiff = value < 0 ? "Duration doesn't  match" : "";
    return errors.durationDiff;
  }
  if (name === "combos" || name === "strikes" || name === "moves") {
    errors[name] = "";
    if (name === "moves" && !isDefense) return errors[name];
    if (isEmpty(value)) {
      errors[name] = "Required";
    }
    return errors[name];
  }
};

const handleDurationError = (value) => {
  if (!value || parseInt(value) <= 0) {
    return "Required";
  }
  if (parseInt(value) > 20) {
    return "Duration must be less than 20 minutes";
  }
  return "";
};

const handleWarmUpError = (name, value) => {
  if (value && parseInt(value) < 0) {
    return "Required";
  }
  if (value > 180) {
    return `${name} Time should be less than 180 seconds`;
  }
  return "";
};

const handleActivityDuration = (value) => {
  if (value && parseInt(value) > 0) {
    return false;
  } else {
    return true;
  }
};

const handleRepsError = (value) => {
  if (value && parseInt(value) > 0 && parseInt(value) < 1000) {
    return false;
  } else {
    return true;
  }
};

export const submitValidationSchema = (rounds, roundsError, levelId, startDate) => {
  let workoutRoundsError;
  let errorList = new Set();
  let workoutError = "";
  let durationError = "";
  let DifficultyError = "";
  let combosError = "";
  let strikesError = "";
  let movesError = "";
  errorList.add(levelId ? false : true);
  errorList.add(Boolean(validationSchema("date", startDate)));
  workoutRoundsError = [...roundsError].map((item, i) => {
    const checkStrikes =
      rounds[i].workoutTypeId === 3 || rounds[i].workoutTypeId === 4;
    const checkActivity = rounds[i].workoutTypeId === 1 || rounds[i].workoutTypeId === 2 || rounds[i].workoutTypeId === 5;

    workoutError = validationSchema("typeId", rounds[i].workoutTypeId);
    DifficultyError = validationSchema("difficulty", rounds[i].roundDifficulty)
    durationError = validationSchema("duration", rounds[i].duration)
    combosError = checkStrikes ? validationSchema("combos", rounds[i].combos) : ""
    strikesError = checkStrikes ? validationSchema("strikes", rounds[i].strikes) : ""
    movesError = checkStrikes ? validationSchema("moves", rounds[i].moves, rounds[i].isDefensive) : ""

    errorList.add(Boolean(workoutError));
    errorList.add(Boolean(DifficultyError));
    errorList.add(Boolean(combosError))
    errorList.add(Boolean(strikesError))
    errorList.add(Boolean(movesError))
    errorList.add(Boolean(durationError))
    if(checkActivity && handleActivityDurationDifference(rounds[i]) !== 0){
      errorList.add(true)
    }

    return {
      ...item,
      workoutTypeError: workoutError,
      roundDifficultyError: DifficultyError,
      combosError: combosError,
      strikesError: strikesError,
      movesError: movesError,
      durationError: durationError,
      activityError:
        checkActivity ?
        item.activityError.map((activity, k) => {
          errorList.add(validationSchema("durationSec", rounds[i].activity_info[k]?.durationSec))
          errorList.add(rounds[i].activity_info[k]?.activityListId ? false : true)
          return {
            ...activity,
            durationError: validationSchema(
              "durationSec",
              rounds[i].activity_info[k]?.durationSec
            ),
            activityError: rounds[i].activity_info[k]?.activityListId
              ? false
              : true,
          };
        }) : [],
    };
  });
  return [workoutRoundsError, errorList.has(true)];
};
